import React from "react"
import Layout from "../components/organisms/layout"
import styled from "styled-components"

const ErrorBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`

const H3 = styled.h3`
  font-size: 4rem;
  margin-top: 0;
  margin-bottom: 5px;
`
const P = styled.p`
  margin-top: 0;
  margin-bottom: 0;
`

const notFound = () => {
  return (
    <div>
      <Layout>
        <ErrorBox>
          <div>
            <H3>404</H3>
            <P>Nie znaleziono takiej strony</P>
          </div>
        </ErrorBox>
      </Layout>
    </div>
  )
}

export default notFound
